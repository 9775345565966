<script>
	import CartItem from './cart-item';
	export default {
		components: { CartItem },
		props: ['cartItems'],
		methods: {
			getTotalPrice(cartItems) {
				let totalPrice = 0;
				cartItems.forEach(item => totalPrice += item.price * item.quantity);
				return totalPrice;
			}
		}
	}
</script>
<template>
	<div class="order-history-cart">
		<div class="cart-items">
			<cart-item
					v-for="item in cartItems"
					:key="item.id"
					:item="item"
			></cart-item>
		</div>
		<h3 class="total-price">
			<span>{{$t('cart-summary')}}</span>
			<span>{{$n(getTotalPrice(cartItems), 'currency')}}</span>
		</h3>
	</div>
</template>
<style scoped>
	.order-history-cart {
		padding: 20px;
		background: var(--greey-bg-color);
		color: #707070;
		line-height: 18px;
		margin-bottom: 32px;
		border-radius: 5px;
	}
	.cart-item:last-of-type {
		padding-bottom: 16px;
		margin-bottom: 14px;
		border-bottom: 1px dashed #70707066;
	}
	.total-price {
		display: flex;
		color: #000000;
		margin: 0;
	}
	.total-price > span:first-of-type {
		flex: 1;
	}
</style>