<script>
	import {getOrders} from '../../functions/api/payment';
	import BackButton from '../../components/back-button';
	import OrderHistoryCart from '../../components/order-history-cart';
	export default {
		components: { BackButton, OrderHistoryCart },
		data() {
			return {
				loading: null,
				orders: []
			}
		},
		created() {
			this.refreshOrders();
		},
		methods: {
			refreshOrders() {
				this.loading = true;
				return getOrders(this.$store.state.userToken)
					.then(res => this.orders = res)
					.catch(err => console.log(err))
					.then(() => this.loading = false);
			}
		}
	}
</script>
<template>
	<div class="container" v-loading="loading === null || loading">
		<back-button></back-button>
		<h1>{{$t('title-order-history')}}</h1>
		<template v-if="loading === false">
			<template v-if="orders.length === 0">
				<section class="placeholder">
					<p>{{$t('order-history-placeholder')}}</p>
				</section>
				<section class="row">
					<router-link to="/buffet-subpage">
						<button class="gold-button">
							{{$t('new-order')}}
						</button>
					</router-link>
				</section>
			</template>
			<template v-else>
				<div
						v-for="order in orders"
						:key="order.id"
				>
					<router-link :to="'/order-progress/' + order.id">
					<h2 class="buffet-name">
							{{order.serial}}
						- {{$t('order-progress-status-' + order.status)}}
					</h2>
					<div class="head">
						<div v-if="order.buffet">
							<router-link :to="`/map/${order.buffet.location}/${order.buffet.title}`">
								<span class="map-icon icon-pin"></span>
								<span class="gold">{{order.buffet.title}}</span>
							</router-link>
						</div>
						<div class="date">
							<span class="icon icon-time"></span>
							<b>{{$d(order.date * 1000, 'date')}}</b>
							<span>{{$d(order.date * 1000, 'time')}}</span>
						</div>
					</div>
					<order-history-cart :cartItems="order.cart.items"></order-history-cart>
					</router-link>
				</div>
			</template>
		</template>
	</div>
</template>
<style scoped>
	#order-history {
		min-height: calc(100vh - 180px);
		display: flex;
		flex-direction: column;
	}
	section.placeholder {
		flex: 1;
	}
	.gold-button {
		width: 100%;
	}
	.buffet-name {
		margin-bottom: 9px;
	}
	.buffet-name a {
		color: var(--theme-color-3);
	}
	.head {
		display: flex;
		margin-bottom: 14px;
	}
	.head > div:first-of-type {
		flex: 1;
	}
	.head .map-icon {
		color: var(--theme-color-3);
	}
	.date b {
		margin-right: 14px;
	}
	.date .icon {
		color: var(--theme-color-3);
		margin-right: 10px;
	}
</style>
