<script>
	export default {
		props: ['item']
	}
</script>
<template>
	<div class="cart-item">
		<span>{{item.title}}{{item.quantity > 1 ? ' x' + item.quantity : ''}}</span>
		<span>{{$n(item.price * item.quantity, 'currency')}}</span>
	</div>
</template>
<style scoped>
	.cart-item {
		display: flex;
	}
	.cart-item:not(:last-of-type) {
		margin-bottom: 9px;
	}
	.cart-item span:first-of-type,
	.total-price span:first-of-type {
		flex: 1;
	}
</style>